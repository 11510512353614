import * as React from "react"
import { Link } from "gatsby"

import Showcase from "../components/features/showcase"
import SimpleNav from "../components/navs/simpleNav"
import Products from "../components/features/products"
import FourColFooter from "../components/footers/fourColFooter"
import LTextDivider from "../components/dividers/lTextDivider"
import SocialOnlyFooter from "../components/footers/socialOnlyFooter"

import { Blue } from "../data/color"
import websites from "../data/websites"

const FAQSite = () => (
  <div>
  
  <SimpleNav color={Blue.color} textColor={Blue.textBold} focusColor={Blue.focusColor} />
  <Showcase bgBold={Blue.bgBold} hoverBold={Blue.hoverBold} name={'WGBH FAQ Site'} desc={'Developed using Salesforce CloudPages and Knowledge Articles '} visit="https://wgbh2.force.com/wgbhnp/s/" picture="https://i.imgur.com/fOLc5Ti.png" />

  <Products name={"More"} projects={websites} textBold={Blue.textBold} textMd={Blue.textMd} />
  <SocialOnlyFooter />

</div>

)

export default FAQSite 